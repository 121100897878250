import React, { FC } from 'react'
import { useMutation } from '@apollo/client'
import { useLocation } from '@reach/router'
import { Button, Col, Form, Input, message, Row } from 'antd'
import { Link, navigate } from 'gatsby'
import queryString from 'query-string'

import { Logo } from '../../assets/logo'
import { CHANGE_PASSWORD } from '../../queries'

import styles from './styles.module.css'

const { Item } = Form

const PasswordRecover: FC = () => {
  const location = useLocation()

  const { emailToken } = queryString.parse(location.search)

  const [changePasswordMutaton, { loading }] = useMutation(CHANGE_PASSWORD)

  const changePassword = async (values: Record<string, string>) => {
    const { email, password } = values
    try {
      const { data } = await changePasswordMutaton({
        variables: {
          emailToken,
          email,
          password,
        },
      })
      if (data?.changePassword) {
        message.success('Contraseña modificada exitosamente')
        navigate('/inicio-sesion/')
      } else message.error('Contraseña no pudo ser modificada')
    } catch (err) {
      console.log(err)
      message.error(err.message)
    }
  }

  return (
    <Row justify="center">
      <Col className={styles.formContainer} xs={24} md={8}>
        <Logo />
        <h2>Cambiar contraseña</h2>
        <Form onFinish={values => changePassword(values)}>
          <p className={styles.alignLeft}>Correo</p>
          <Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'el correo no es válido',
              },
              { required: true, message: 'Este campo es obligatorio' },
            ]}>
            <Input placeholder="Correo" className={styles.input} />
          </Item>
          <p className={styles.alignLeft}>Contraseña</p>
          <Item
            name="password"
            rules={[
              { required: true, message: 'Este campo es obligatorio' },
              {
                pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*.?&#])[A-Za-z\d@$!%*.?&#]{8,}/,
                message: 'Introduzca una contraseña válida',
              },
            ]}>
            <Input.Password placeholder="Contraseña" className={styles.input} />
          </Item>
          <p className={styles.alignLeft}>Confirmar contraseña</p>
          <Item
            name="password_validation"
            rules={[
              { required: true, message: 'Este campo es obligatorio' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value)
                    return Promise.resolve()
                  return Promise.reject(
                    new Error('las contraseñas no coinciden'),
                  )
                },
              }),
            ]}>
            <Input.Password
              placeholder="Confirmar contraseña"
              className={styles.input}
            />
          </Item>
          <Button
            htmlType="submit"
            block
            loading={loading}
            className={styles.button}>
            Aceptar
          </Button>
          <Row justify="center">
            <Col>
              <Link to="/">Volver al sitio</Link>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}

export default PasswordRecover
